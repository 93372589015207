// Variables
@import './utilities/variables';
.app_wrapper.loading {
	.app_content {
		// filter: blur(5px);
		opacity: 0.25;
	}
}

.message {
	width: 30%;
	padding-bottom: 20%;
	background: white;
	border-radius: 10px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -20%);
	z-index: 1011;
	visibility: hidden;
	opacity: 0;
	transition: opacity 1s ease, visibility 0s 1s, transform 0s 1s ease;
	&.visible {
		transition: opacity 0.5s 0.5s ease, visibility 0s,
			transform 0.5s 0.5s ease;
		visibility: visible;
		opacity: 1;
		transform: translate(-50%, -50%);
	}
	p {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: 0;
		text-align: center;
	}
}

.message_overlay {
	background: rgba(255, 255, 255, 0);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1010;
	display: block;
	visibility: hidden;
	transition: background 1.5s ease, visibility 0s 1.5s;
	&.visible {
		// display: block;
		visibility: visible;
		background: rgba(0, 0, 0, 0.5);
		transition: background 1.5s ease, visibility 0s 0s;
	}
}
