// Variables
@import './utilities/variables';

.menu {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    background: rgba(255, 255, 255, 0);
    transition: background 0.5s ease;
    text-align: right;
    padding-right: 15px;
    box-sizing: border-box;

    &.solid {
        background: rgba(255, 255, 255, 0.8);
    }

    // background: grey;
    // width: 100px;
    height: 70px;

    @include menu-small {
        display: none;
    }

    a {
        display: inline-block;
        padding: 0 10px;
        line-height: 70px;
        font-weight: regular;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        .hover {
            &:before,
            &:after {
                bottom: 25px;
            }
        }

        span {
            display: inline-block;
        }
    }

    @include menu-medium {
        a {
            padding: 0 7px;
            line-height: 70px;
            font-weight: regular;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-size: 16px;

            .hover {
                &:before,
                &:after {
                    bottom: 25px;
                }
            }

            span {
                display: inline-block;
            }
        }
    }
}
