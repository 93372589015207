// Variables
@import './utilities/variables';


.banner {
    // padding-bottom: 50%;
    position: relative;

    .landing_banner_image {

        @include respond-to(handhelds) {
            padding-bottom: $handheld_padding;
        }

        @include respond-to(medium-screens) {
            padding-bottom: $medium_padding;
        }

        @include respond-to(wide-screens) {
            padding-bottom: $large_padding*1.2;
        }

        &.no_image {
            padding: 50px 0;
        }
    }

    .button_holder {
        position: absolute;
        top: 50%;
        width: 80%;
        left: 10%;
        text-align: center;
        transform: translateY(-50%);

        h1 {
            position: relative;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            margin: 0 0 30px 0;
            font-size: $h1_font_size;
            font-size: 4vw;

            @include phone {
                font-size: 8vw;
            }

            font-weight: 900;
            // opacity: .8;
            // max-width: 80%;
            overflow: hidden;
            word-wrap: break-word;
            text-transform: uppercase;

        }



        a {
            display: inline-block;
            clear: both;
            background: $primary;
            color: $white;
            padding: 10px 20px;

            @include respond-to(medium-screens) {

                padding: 10px 20px;
            }

            @include respond-to(wide-screens) {
                padding: 10px 20px;

            }

            margin: 20px 0;
            border-radius: $button_border_radius;
            font-weight: $button_font_weight;
            letter-spacing: $button_letter_spacing;
            font-size: $button_font_size;
        }
    }

    .landing_banner_image.no_image {

        .button_holder {
            position: relative;
            top: 0;
            width: 80%;
            left: 10%;
            text-align: center;
            transform: none;
        }
    }






}