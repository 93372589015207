// Variables
@import './utilities/variables';

.circle-loader {
    // margin: 0 0 30px 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-color: #5cb85c;

    &.color_primary {
        border-left-color: $primary;
    }

    &.color_white {
        border-left-color: $white;
    }

    animation-name: loader-spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 40px;
    width: 100%;
}

.circle-loader,
.circle-loader:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
}

.load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: #5cb85c;

    &.color_primary {
        border-color: $primary;
    }

    &.color_white {
        border-color: $white;
    }

    transition: border 500ms ease-out;
}

.checkmark {
    display: none;

    &.draw {
        display: block;
    }
}

.checkmark.draw:after {
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
}

.checkmark:after {
    opacity: 1;
    height: 2.5em;
    width: 1.25em;
    transform-origin: left top;
    border-right: 2px solid #5cb85c;
    border-top: 2px solid #5cb85c;
    content: '';
    left: 1.25em;
    top: 2.5em;
    position: absolute;
}

.color_white {
    .checkmark:after {
        border-right: 2px solid $white;
        border-top: 2px solid $white;
    }
}

.color_primary {
    .checkmark:after {
        border-right: 2px solid $primary;
        border-top: 2px solid $primary;
    }
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }

    20% {
        height: 0;
        width: 1.25em;
        opacity: 1;
    }

    40% {
        height: 2.5em;
        width: 1.25em;
        opacity: 1;
    }

    100% {
        height: 2.5em;
        width: 1.25em;
        opacity: 1;
    }
}
