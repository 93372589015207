// Variables
@import './utilities/variables';

.contact_form_wrapper {
    background: $primary;
    &.background_white {
        background: none;
    }
}

.contact_form {
    width: 100px;

    width: $phone_content_width;
    margin: 50px auto;
    border-radius: 3px;
    padding: 50px 0;
    // padding-bottom: 30px;

    box-sizing: border-box;

    @include respond-to(medium-screens) {
        width: $medium_content_width;
        max-width: $medium_max_content_width;
    }

    @include respond-to(wide-screens) {
        width: $large_content_width;
        max-width: $large_max_content_width;
    }

    .text {
        // padding: 30px;

        h1 {
            text-align: left;
            color: $white;
            .background_white & {
                color: $primary;
            }

            &:first-child {
                margin-top: 0;
            }
        }

        p {
            color: $white;
            .background_white & {
                color: $primary;
            }
        }
    }

    .form {
        // background: green;
        min-height: 100px;
        width: 56%;
        float: left;

        // margin-left: 6.66%;
        @include phone {
            width: 100%;
            margin: 20px 0 0 0;
            transform: none;
        }

        form {
            label {
                color: $white;
                display: block;
                .background_white & {
                    color: $primary;
                }
                // margin-top: 20px;

                a {
                    color: white;
                    text-decoration: underline;
                    .background_white & {
                        color: $primary;
                    }
                }

                input {
                    // width: auto;
                    // display: inline;
                }
            }

            *:first-child {
                margin-top: 0;
            }

            *:last-child {
                margin-bottom: 0;
            }
        }

        button {
            transition: box-shadow 0.5s ease, transform 0.5s ease;
            transform: scale(0.98);
            cursor: pointer;

            @include phone {
                width: 100%;
            }

            &:hover {
                transform: scale(1);
                box-shadow: $box_shadow;
                cursor: pointer;
            }
        }
    }

    .contact {
        background: $white;
        box-shadow: $box_shadow_large;
        min-height: 100px;
        width: 33%;
        margin-right: 00%;
        // transform: translate(15px, 0);
        position: relative;
        padding: 40px;
        padding-bottom: 60px;
        box-sizing: border-box;
        float: right;

        @include phone {
            width: 100%;
            margin: 20px 0;
            transform: none;
        }

        .image {
            width: 90%;
            padding-bottom: 90%;
            background: blue;
            border-radius: 50%;
            left: 5%;
            // margin-top: 30px;
        }

        .name {
            margin: 20px 0 10px 0;
            text-align: center;
        }

        .email {
            display: block;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            text-align: center;

            @include tablet {
                font-size: 75%;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
