// Variables
@import './utilities/variables';

@keyframes custom_fade_in {
  0% {
    opacity: 0;
    // transform: translateY(10px);
  }

  100% {
    opacity: 1;
    // transform: translateY(0px);
  }
}

.custom_fade_in {
  animation: custom_fade_in;
  animation-timing-function: ease-out;
}

/** Hover-related CSS **/

// .menu {
// display: flex;
// flex-flow: row nowrap;
// align-items: center;
// margin: 0 0 45px;

$animate: all 0.2s ease-in-out;

a {
  .hover {
    // font-size: 36px;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    transition: $animate;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      width: 0%;
      height: 2px;
      left: 0;
      // margin: 15px;
      transition: $animate;
      transition-duration: 0.4s;
      opacity: 0;
      // background-color: darken($primary, 5%);
      background-color: $primary;
    }
    &.turquoise .hover {
      &:before,
      &:after {
        background-color: #099c99;
      }
    }
  }

  &:hover {
    .hover {
      &:before,
      &:after {
        width: 100%;
        opacity: 1;
      }
    }
  }
}
