// Variables
@import './utilities/variables';

p {
    color: $text-color;
    line-height: 130%;
    margin-bottom: 30px;
    margin-top: 0;
    font-family: PT Sans, sans-serif;
}

h1 {
    font-size: $h1_font_size;
    line-height: 130%;
    color: $primary;
    letter-spacing: 0.5px;
    font-weight: 600;
    word-wrap: normal;
}

.parallax-banner h1 {
    font-size: 5vw;
}

h2,
.h2 {
    color: $primary;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: $h2_font_size;
    word-wrap: normal;
    hyphens: none;
}

h3 {
    color: $primary;
    word-wrap: normal;
    font-size: $h3_font_size;
    font-weight: 600;
    margin-top: 0;

    @include tablet {
        font-size: $h3_font_size_large;
        line-height: 150%;
    }
}

input,
textarea {
    font-family: PT Sans, sans-serif;
}

a {
    color: $primary;
    text-decoration: none;
    // left: 0;
}

a:hover {
    text-decoration: none;

    .info .description p {
        text-decoration: none;
    }
}

.align_left,
.align_left p {
    text-align: justify;
}

.force_left,
.force_left p {
    text-align: left !important;
}

.text_block {
    font-size: 18px;

    img {
        width: 100%;
        height: auto;
    }

    .wp-caption-text {
        margin-top: 0;
    }

    div.wp-caption {
        width: 100% !important;
        margin: 30px 0;
    }
}

a.button,
button {
    border-radius: 3px;
    display: inline-block;
    border: 1px solid $primary;
    padding: 15px 20px;

    // @include respond-to(medium-screens) {
    //     padding: 20px 40px;
    // }

    // @include respond-to(wide-screens) {
    //     padding: 20px 40px;

    // }

    background: none;
    color: $primary;
    cursor: pointer;
    transition: background $button_transition_time ease,
        color $button_transition_time ease;

    &:hover,
    &.active {
        background: $primary;
        color: $white;
    }

    &:disabled,
    &[disabled] {
        &,
        &:hover {
            border: 1px solid $grey;
            color: $grey;
            background: none;
            cursor: default;
        }
    }
}

button.link {
    border: none;
    padding: none;
    display: inline-block;
}

button.blk {
    display: inline-block;
    margin: 20px;
}

.align_center {
    text-align: center !important;
}

span.primary {
    color: $primary;
}

.justify,
.justify p {
    text-align: justify !important;
}

.red {
    color: $red;
}
