// Variables
@import './utilities/variables';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
    /*background: grey;*/
    padding: 0;
    /*padding-top: 35px;*/
    font-size: 18px;
    margin: 0;
    // overflow-x: hidden;
    /*min-height: 200%;*/
    font-family: IBM Plesk Sans, sans-serif;
}

/*color: #64b246*/
/*#868686*/

body {
    /*padding-top: 140px;*/
}

.clearfix {
    clear: both;
}

button,
a.button {
    outline: none;
    font-size: $button_font_size;
    letter-spacing: $button_letter_spacing;
    margin: 10px 0px;

    &:active {
        outline: none;
    }

    &.link {
        border: none;
        padding: 0;
        margin: 0;

        &:hover {
            background: none;
            text-decoration: underline;
            color: $primary;
        }
    }

    &.red {
        color: $red;
        border: 1px solid $red;

        &:hover {
            background: $red;
            color: $white;
        }
    }

    &.solid {
        color: $white;
        background: $primary;
    }

    &.white {
        background: $white;
        color: $primary;
    }

    &.outline.white {
        background: none;
        color: white;
        border: 1px solid $white;

        &:hover {
            // color: $grey;
            // background: $white;
        }
    }
}

h1 {
    text-align: center;

    &.left {
        text-align: left;
    }
}

h1.white {
    color: white;
}

p.general_text {
    text-align: center;
    width: 50%;
    margin-left: 25%;
    color: #868686;
}

ul {
    text-align: left;
    color: $text_color;
    padding-left: 21px;

    li {
        display: block;
        list-style: none;
        margin: 0 0 16px 0;
        line-height: 130%;

        @include no-phone {
            line-height: 150%;
        }

        // margin: 0 0 16px 1.1225em;
        /* Give the bullet room on the left hand side */

        padding: 0;
        position: relative;
    }

    li::before {
        color: $primary;
        /* color of bullet or square */

        content: '\2022';
        /* Unicode of character to precede the list item */

        display: inline-block;
        font-size: 1em;
        /* use em or % */

        left: -1.1225em;
        /* use em, line up bullet flush with left hand side */

        position: absolute;
        /* Set the bullet positioned absolutely top left */

        top: 0em;
        /* use em or % */
    }
}

.hidden_on_mobile {
    @include respond-to(handhelds) {
        display: none;
    }
}

.hidden_on_desktop {
    @include respond-to(medium-screens) {
        display: none;
    }

    @include respond-to(wide-screens) {
        display: none;
    }
}