// Variables
@import './utilities/variables';

select {
    appearance: none;
    background: none;
}

textarea {
}

.field {
    position: relative;
    // padding: 1px;
    margin: 20px 0;
    // transition: padding 0.5s ease;

    &.left {
        width: 47.5%;
        display: inline-block;
        margin-bottom: 0;
    }
    &.right {
        width: 47.5%;
        margin-left: 5%;
        display: inline-block;
        margin-bottom: 0;
    }

    .error_msg {
        color: $primary;
        position: relative;
        top: 0;
        font-size: 15px;
        font-weight: 500;
        opacity: 0;
        transition: opacity 0.5s 0s ease, margin 0.5s ease;
        margin-bottom: 0px;
        display: block;
    }

    .required {
        position: relative;
        display: block;
    }

    & .required::after {
        content: '!';
        display: block;
        position: absolute;
        font-weight: bold;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        color: $white;
        opacity: 0;
        transition: opacity 0.5s 0s ease, right 0s 2s ease;

        // width: 10px;
        // height: 10px;
        // background: red;
    }

    &.error {
        // padding-top: 25px;
        font-weight: bold;

        label {
            font-weight: bold;
        }

        .error_msg {
            opacity: 1;
            margin-bottom: 20px;
            transition: opacity 0.5s 0s ease, margin 0.5s ease;
        }

        & .required::after {
            opacity: 1;
            right: 20px;
            transition: opacity 0.5s 0.5s ease, right 0.5s 0.5s ease;
        }
    }
}

.background_color {
    .field {
        .error_msg {
            color: white;
        }
    }
}

input:not([type='checkbox']),
select,
textarea {
    resize: none;
    color: $primary;
    display: block;
    width: 100%;
    line-height: 150%;
    padding: 12px;
    border-radius: 3px;
    // color: $primary;
    border: 1px solid $primary;
    font-size: 15px;
    box-sizing: border-box;

    &::placeholder {
        color: $primary;
    }

    &:focus {
        outline: none;
        border: 2px solid $primary;
        padding: 11px;
        color: $primary;
    }

    &[hidden] {
        display: none;
    }

    &.white {
        background: transparent;
        color: $white;
        border-color: $white;

        &::focus {
            background: none;
            color: $white;
            border-color: $white;
        }

        &::placeholder {
            color: $white;
        }
    }
}

form label {
    color: $primary;
}

.error input {
    color: $red;
    border: solid 2px $red;
    padding: 11px;

    &::placeholder {
        color: $red;
    }

    &.white,
    &.white:focus {
        background: none;
        color: $white;
        border-color: $white;

        &::placeholder {
            color: $white;
        }
    }

    &:focus {
        color: $primary;
        border: solid 2px $primary;
        padding: 11px;

        &::placeholder {
            color: $primary;
        }
    }
}

.ui.grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 10px 0;

    .column {
        display: flex-box;
        margin: 0 5px;
        flex: 1;

        input,
        select {
            margin: 0;
        }

        select {
            height: 100%;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.form_holder {
    position: relative;

    form {
        transition: opacity 1s ease;
    }

    .form_overlay {
        visibility: hidden;
        //     opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //     background: $primary;
    }

    .loading_holder {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%) translateY(20px);
        // background: blue;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease, transform 0.5s ease;
        text-align: center;

        .circle-loader {
            position: inline-block;
            // left: 50%;
            // transform: translateX(-50%);
        }

        .messages {
            position: relative;
            text-align: center;
            height: 50px;
            margin-top: 20px;

            .loading_message,
            .success_message,
            .error_message {
                position: absolute;
                display: block;
                text-align: center;
                width: 100%;
                top: 50%;
                // left: 50%;
                text-align: center;
                color: $white;
                transform: translate(0, -50%);
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

.background_white {
    .form_holder {
        .messages {
            .loading_message,
            .success_message {
                color: $primary;
            }
        }
    }
}

.form_holder.loading {
    form {
        opacity: 0;
    }

    .form_overlay {
        visibility: visible;
        // opacity: .5;
    }

    .loading_holder {
        opacity: 1;
        visibility: visible;

        transform: translate(-50%, -50%) translateY(0px);

        .loading_message {
            visibility: visible;
            opacity: 1;
        }
    }
}

.form_holder.success {
    .form_overlay {
        visibility: visible;
        // opacity: .5;
    }

    form {
        opacity: 0;
    }

    .loading_holder {
        opacity: 1;
        visibility: visible;

        transform: translate(-50%, -50%) translateY(0px);

        .success_message {
            visibility: visible;
            opacity: 1;
        }
    }
}

.singup_form {
    .shipping_address {
        max-height: 0;
        transition: max-height 1s linear;
        overflow: hidden;

        &.open {
            max-height: 1000px;
        }
    }

    .file_name {
        color: $primary;
        text-align: center;
    }

    .signup {
    }

    .loading {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        opacity: 0.9;
        display: none;
        border-radius: 5px;

        span {
            position: absolute;
            top: 50%;
            text-align: center;
            transform: translateY(-50%);
            display: block;
            width: 100%;
            color: $primary;
        }
    }

    &.loading .loading {
        display: block;
    }
}

/*Checkboxes styles*/
input[type='checkbox'] {
    display: none;
}

input[type='checkbox'] + label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font: 14px/20px 'Open Sans', Arial, sans-serif;
    color: $primary;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

input[type='checkbox'].white + label {
    color: $white;
}

input[type='checkbox'] + label:last-child {
    margin-bottom: 0;
}

input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid $primary;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    -webkit-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
}

input[type='checkbox'].white + label:before {
    border: 1px solid $white;
}

input[type='checkbox']:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    border-width: 2px;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
