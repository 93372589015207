// Variables
@import './utilities/variables';

.people_display {
    position: relative;
    top: 0;
    margin-top: 50px;

    h1 {
        margin: 50px 0 0 0;
    }

    .people {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;

        @include respond-to(medium-screens) {
            width: $medium_content_width * 1.06;
            max-width: $medium_max_content_width * 1.06;
        }

        @include respond-to(wide-screens) {
            width: $large_content_width * 1.06;
            max-width: $large_max_content_width * 1.06;
        }

        // justify-content: space-between;
        .person {
            width: 33.33%;
            padding: 0 3%;
            position: relative;
            box-sizing: border-box;
            margin: 50px 0;

            @include respond-to(handhelds) {
                width: $phone_content_width;
                padding: 0;
                margin: 50px auto;
                max-width: $medium_max_content_width;
            }

            .text {
                @include tablet {
                    font-size: 16px;
                }

                h2,
                .role,
                .email,
                .website {
                    display: block;
                    margin: 20px 0;
                }

                .email {
                    word-wrap: break-word;
                }

                p {
                    word-wrap: break-word;
                    hyphens: auto;
                    line-height: 130%;
                    font-size: $font_size;

                    @include tablet {
                        font-size: 16px;
                    }

                    @include no-phone {
                        line-height: 150%;
                    }
                }
            }

            .image {
                transform: translate(-1px, -1px);
                position: relative;
                width: 50%;
                margin-left: 25%;
                padding-bottom: 50%;
                height: 0;
                position: relative;
                border-radius: 50%;
                overflow: hidden;

                .parallax_holder {
                    position: absolute;
                    height: 0 !important;
                    padding-bottom: 100%;
                }
            }
        }
    }
}
