// Variables
@import './utilities/variables';

.single_event {
  .header_image {
    position: relative;
    background: $bg_grey;
    padding-bottom: $handheld_padding;

    @include tablet {
      padding-bottom: $medium_padding;
    }

    @include desktop {
      padding-bottom: $large_padding;
    }

    .image_holder {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    // .header_image_parallax {
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     background: $bg_grey;
    //     overflow: hidden;

    //     .parallax-inner {
    //         width: 100%;
    //         height: 120%;
    //         position: absolute;
    //         top: -10%;
    //         left: 0;
    //         // background: blue;

    //         .image_holder {
    //             position: absolute;
    //             width: 100%;
    //             height: 100%;
    //             top: 0;
    //             left: 0;
    //         }
    //     }
    // }
  }

  .heading_holder {
    margin: 50px 0;
    font-size: $site_heading_font_size;
  }

  .description {
    img {
      &.full_width {
        width: 100%;
      }
      width: 15%;
      margin: 5px 20px 15px 0;
      float: left;
      //   margin: 0;
      @include phone {
        margin: 0 20px 5px 0;
        width: 50%;
      }
    }

    .clear {
      clear: both;
    }
  }

  .sign_up_wrapper {
    background: $primary;

    .sign_up {
      width: 100px;

      width: $phone_content_width;
      margin: 50px auto;
      border-radius: 3px;
      padding: 50px 0;
      // padding-bottom: 30px;

      box-sizing: border-box;

      @include respond-to(medium-screens) {
        width: $medium_content_width;
        max-width: $medium_max_content_width;
      }

      @include respond-to(wide-screens) {
        width: $large_content_width;
        max-width: $large_max_content_width;
      }

      .text {
        // padding: 30px;

        h1 {
          text-align: left;
          color: $white;

          &:first-child {
            margin-top: 0;
          }
        }

        p {
          color: $white;
        }
      }

      .form {
        // background: green;
        min-height: 100px;
        width: 56%;
        float: left;

        // margin-left: 6.66%;
        @include phone {
          width: 100%;
          margin: 20px 0 0 0;
          transform: none;
        }

        form {
          label {
            color: $white;
            display: block;
            // margin-top: 20px;

            a {
              color: white;
              text-decoration: underline;
            }

            input {
              // width: auto;
              // display: inline;
            }
          }

          *:first-child {
            margin-top: 0;
          }

          *:last-child {
            margin-bottom: 0;
          }
        }

        button {
          transition: box-shadow 0.5s ease, transform 0.5s ease;
          transform: scale(0.98);
          cursor: pointer;

          @include phone {
            width: 100%;
          }

          &:hover {
            transform: scale(1);
            box-shadow: $box_shadow;
            cursor: pointer;
          }
        }
      }

      .contact {
        background: $white;
        box-shadow: $box_shadow_large;
        min-height: 100px;
        width: 33%;
        // margin-right: 10%;
        // transform: translate(15px, 0);
        position: relative;
        padding: 40px;
        padding-bottom: 60px;
        box-sizing: border-box;
        float: right;

        @include phone {
          width: 100%;
          margin: 20px 0;
          transform: none;
        }

        .image {
          width: 90%;
          padding-bottom: 90%;
          background: blue;
          border-radius: 50%;
          left: 5%;
          // margin-top: 30px;
        }

        .name {
          margin: 20px 0 10px 0;
          text-align: center;
        }

        .email {
          display: block;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          text-align: center;

          @include tablet {
            font-size: 75%;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
