// Variables
@import './utilities/variables';

// @include respond-to(handhelds) {
// }

// @include respond-to(medium-screens) {
// }

// @include respond-to(wide-screens) {
// }

.footer_wrapper {
    width: 100%;
    // padding-bottom: 20%;
    background: $bg_grey;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);

    .footer {
        .logo {
            background: url('../img/logo.svg');
            background-size: contain;
            background-position: center;
            width: 60%;
            padding-bottom: 20%;
            // background: url('../img/logo.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin: 40px auto;
        }
    }

    .footer.desktop {
        @include mobile-footer() {
            display: none;
        }

        .logo_holder {
            width: $large_content_width;
            margin: auto;
            margin-top: 50px;
        }

        .footer_text {
            font-family: PT Sans, sans-serif;
            font-size: $footer_font_size;

            .button {
                margin: 0 0 10px 0;
                &.contact {
                    margin: 0 0 30px 0;
                }
            }
        }

        .flex {
            width: 80%;
            overflow: hidden;
            margin: 0 auto 50px auto;
            display: flex;
            min-width: $break-footer - 50;
            max-width: 1300px;
            justify-content: space-between;

            .info_left {
                margin: 0 30px 0 0;
                padding-top: 60px;
                position: relative;

                .logo {
                    margin: 0 0 30px 0;
                    width: 70%;
                    height: 50px;
                    position: absolute;
                    padding: 0;
                    top: 0;
                    left: 0;
                    background-position: top left;
                }

                // background: #ff0000cc;

                p {
                    margin: 0 0 20px 0;
                }
            }

            .link_block {
                padding-top: 60px;
                // background: #ff0000cc;
                margin: 0 10px;

                a {
                    font-size: $footer_font_size;
                    display: block;
                    color: $text-color;
                    margin: 0 0 20px 0;

                    &:hover {
                        text-decoration: underline;
                    }

                    &.main {
                        color: $primary;
                        // font-size: $h3_font_size;
                    }
                }
            }
        }
    }

    .footer.mobile {
        max-width: $max-width;
        margin: 0 auto;
        display: none;
        padding-bottom: 50px;

        @include mobile-footer() {
            display: block;
        }

        .name {
            color: $primary;
            text-align: center;
            display: block;
            letter-spacing: 1px;
            margin: 50px 0;
        }

        .link_holder {
            width: 80%;
            margin: 20px 10%;
            text-align: center;

            a {
                display: block;
                margin: 20px 0;
                font-size: $h3_font_size;
                // text-transform: uppercase;
            }
        }

        .imprint {
            color: $primary;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 15px;
        }
    }
}

html,
body,
#root,
.app_wrapper {
    height: 100%;
    position: relative;
}

.page_content {
    box-sizing: border-box;
    position: relative;
    min-height: 100%;
}

html,
body {
    height: 100%;
}
