// Variables
@import './utilities/variables';

// @include respond-to(medium-screens) {
// @include respond-to(wide-screens) {

.news_display {
    .single_news_overview {
        display: flex;
        flex-direction: row;
        align-items: center;
        // min-height: 50vh;
        padding: 0 (100-$large_content_width)/2;
        box-sizing: border-box;
        margin: 50px 0;

        @include respond-to(handhelds) {
            margin: 0;
        }


        @include respond-to(handhelds) {

            display: block;
            min-height: 0;
            padding: 0;
        }

        .left {
            position: relative;
            width: 40%;
            padding-bottom: 25%;
            // background: #000000aa;

            @include respond-to(handhelds) {
                width: 100%;
                padding-bottom: 0;
            }

            .image_holder {
                width: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                background-color: $bg_grey;

                .news_image {
                    padding-bottom: 61%;
                }

                .title_holder {
                    display: none;
                }

                @include respond-to(handhelds) {
                    position: relative;
                    width: 100%;
                    left: 0;
                    top: 0;
                    transform: none;


                    .title_holder {
                        display: block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 80%;
                        margin: 0 10%;


                        .date {
                            text-align: center;
                            color: $primary;
                            display: block;
                            // margin: 50px 0 0 0;
                        }

                        h1 {
                            margin: 0;
                            font-size: $h1_font_size;
                            font-weight: 900;
                            opacity: .8;
                            overflow: hidden;
                            word-wrap: break-word;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }

        .right {
            width: 60%;
            text-align: left;
            box-sizing: border-box;
            padding: 0 0 0 10%;

            @include respond-to(handhelds) {
                padding: 0 (100-$phone_content_width)/2;
            }


            h1 {
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            .date {
                color: $primary;
                font-size: $h3_font_size;
                margin: 10px 0;
                // background: red;
                display: block;
            }



            .text_block {
                margin: 0;
                text-align: left;
                width: 100%;

                @include phone {
                    margin: 50px 0;
                }

                &.button_holder {
                    text-align: left;

                    @include respond-to(handhelds) {
                        text-align: center;
                        margin: -20px 0 50px 0;
                    }
                }
            }

            @include respond-to(handhelds) {
                width: 100%;
            }




        }
    }


    .single_news {
        .parallax_holder {
            padding-bottom: $large_padding;
            background-color: $bg_grey;

            @include respond-to(handhelds) {
                display: block;
                padding-bottom: $handheld_padding;
            }

            .title_holder {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 80%;
                margin: 0 10%;
                display: none;

                @include respond-to(handhelds) {
                    display: block;
                }

                .date {
                    text-align: center;
                    color: $primary;
                    display: block;

                    // margin: 50px 0 0 0;
                    &.hidden_on_mobile {
                        @include respond-to(handhelds) {
                            display: none;
                        }
                    }
                }

                h1 {
                    margin: 0;
                    font-size: $h1_font_size;
                    font-weight: 900;
                    opacity: .8;
                    overflow: hidden;
                    word-wrap: break-word;
                    text-transform: uppercase;
                }
            }
        }

        .text_block {
            width: $large_content_width;
            margin: 50px auto;


            @include respond-to(medium-screens) {

                width: $medium_content_width;
            }

            @include respond-to(handhelds) {

                width: $phone_content_width;
            }

            .title_holder {
                margin: 50px auto;
                text-align: center;

                @include respond-to(handhelds) {
                    display: none;
                }

                .date {
                    color: $primary;
                }

                h1 {
                    text-transform: uppercase;


                }
            }
        }
    }
}