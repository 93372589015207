// Variables
@import './utilities/variables';

a.contact_button_menu {
    left: 240px;
    margin: 0;
    position: fixed;
    top: 33px;
    transform: translateY(-50%);
    padding: 10px 20px;

    @include phone {
        position: fixed;
        bottom: 10px;
        right: 10px;
        top: unset;
        left: unset;
        transform: none;
        margin: 0;
        color: $white;
        background: $primary;
    }
}
