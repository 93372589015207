$grey: #868686;
$primary: #d5095c;
$primary_rgba_70: rgba(213, 9, 92, 0.5);
$primary_rgba_100: rgba(213, 9, 92, 0.9);

$turquoise: #00aaa5;
$blue: #0087e6;

$white: #ffffff;
$bg_grey: #f4f4f4;
$red: #d5095c;

$text-color: #333;

$trans_white: rgba(255, 255, 255, 0.9);

$max_width: 1000px;

$general_transition_time: 0.5s;
$button_transition_time: 0.2s;
$link_transition_time: 0.2s;
$menu_transition_time: 0.5s;
$overlay_transition_time: 1s;

$button_font_size: 15px;
$button_letter_spacing: 0.5px;

$h1_font_size: 35px;
$site_heading_font_size: 3.3vw;
$h2_font_size: 30px;
$h3_font_size: 18px;

$h3_font_size_large: 25px;

$quote_font_size: 30px;
$small_font: 12px;
$font_size: 18px;
$medium_font_size: 15px;

$footer_font_size: 14px;

$box_shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
$box_shadow_medium: 0px 0px 20px 0px rgba(100, 100, 100, 0.3);
$box_shadow_large: 0px 0px 30px 0px rgba(100, 100, 100, 0.5);

$button_border_radius: 2px;
$button_font_weight: 700;

$phone_content_width: 80%;
$medium_content_width: 80%;
$large_content_width: 60%;

$medium_max_content_width: 700px;
$large_max_content_width: 900px;

$handheld_padding: 70%;
$medium_padding: 40%;
$large_padding: 30%;

$break-small: 550px;
$break-large: 1150px;

$break-footer: 950px;
$break-menu: 1400px;
$break-menu-small: 1250px;

@mixin phone {
  @media only screen and (max-width: $break-small) {
    @content;
  }
}

@mixin mobile-footer {
  @media only screen and (max-width: $break-footer) {
    @content;
  }
}

@mixin no-phone {
  @media only screen and (min-width: $break-small + 1) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $break-large) {
    @content;
  }
}

@mixin menu-medium {
  @media only screen and (min-width: $break-menu-small + 1) and (max-width: $break-menu - 1) {
    @content;
  }
}

@mixin menu-small {
  @media only screen and (max-width: $break-menu-small) {
    @content;
  }
}

@mixin respond-to($media) {
  @if $media==handhelds {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media==medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
      @content;
    }
  } @else if $media==wide-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  }
}
