// Variables
@import './utilities/variables';

.video_player {
    width: 100%;
    margin: 50px auto;
    position: relative;

    @include tablet {
        width: $medium_content_width;
        max-width: $medium_max_content_width;
    }

    @include desktop {
        width: $large_content_width;
        max-width: $large_max_content_width;
    }

    @include phone {
        h1,
        p {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            text-align: justify;
        }
    }

    .video_wrapper {
        position: relative;
        width: 100%;
        padding-bottom: 56.3%;

        .cover {
            // display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s ease, visibility 0s 0.5s;
            cursor: pointer;

            .play_holder {
                // background: $primary_rgba_70;
                transition: background 1s ease;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .play {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100px;
                    height: 100px;
                    transform: translate(-50%, -50%) scale(1);
                    // opacity: .8;
                    transition: opacity 0.5s ease, transform 1s ease;
                    background: url('../../img/play.svg');
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            &:hover .play_holder {
                // background: $primary_rgba_100;

                .play {
                    transform: translate(-50%, -50%) scale(1.1);
                    opacity: 1;
                }
            }

            &.visible {
                opacity: 1;
                visibility: visible;
                transition: opacity 0.5s ease, visibility 0s;
            }
        }

        .video {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
