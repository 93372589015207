// Variables
@import './utilities/variables';



.landing_banner.header_image {
    // padding-bottom: 50%;
    position: relative;



    .image_wrapper {
        @include respond-to(handhelds) {
            padding-bottom: $handheld_padding;
            // margin-bottom: 25%;
        }

        @include respond-to(medium-screens) {
            padding-bottom: $medium_padding*1.3;
        }

        @include respond-to(wide-screens) {
            padding-bottom: $large_padding*1.3;
        }

        .header_image_parallax .parallax-inner .image_holder {
            top: -13%;
        }
    }



    .button_holder {
        position: absolute;
        top: 90%;
        width: 80%;
        left: 10%;
        text-align: center;
        transform: translateY(-50%);

        @include no-phone {
            top: 60%;
            width: 40%;
            left: 50%;
        }

        a {
            display: inline-block;
            background: $primary;
            color: $white;
            padding: 10px 20px;
            margin: 10px 0;


            @include respond-to(medium-screens) {

                margin: 20px 0;
                padding: 10px 20px;
            }

            @include respond-to(wide-screens) {
                margin: 20px 0;
                padding: 10px 20px;

            }

            border-radius: $button_border_radius;
            font-weight: $button_font_weight;
            letter-spacing: $button_letter_spacing;
            font-size: $button_font_size;
        }


    }

    .quote,
    .quotee {
        color: $primary;
        // font-size: $h2_font_size;
        font-size: 2vw;
        text-align: center;
        margin: 0px 0 20px 0;

        @include phone {
            font-size: 4vw;
        }

    }

    .quotee {
        margin: 0px 0 30px 0;
    }


    .quote_holder {

        @include respond-to(handhelds) {
            display: none;
        }

    }

    .quote_holder_mobile {
        width: 80%;
        margin: 40px auto 50px auto;


        @include respond-to(medium-screens) {

            display: none;
        }

        @include respond-to(wide-screens) {
            display: none;
        }

        .quote,
        .quotee {
            color: $primary;
            font-size: $h3_font_size;
            text-align: center;
            margin: 0 0 10px 0;
        }

        .quotee {
            margin-bottom: 30px;
        }



        a {
            display: inline-block;
            background: $primary;
            color: $white;
            padding: 10px 20px;
            margin: 10px 0;

            &:last-child {
                margin-bottom: 0;
            }

            border-radius: $button_border_radius;
            font-weight: $button_font_weight;
            letter-spacing: $button_letter_spacing;
            font-size: $button_font_size;
        }

    }


}