// Variables
@import './utilities/variables';

.workshop_mindmap {
  // padding-bottom: 62.4%;

  position: relative;
  margin: 50px auto;

  @include tablet {
    width: $medium_content_width;
    max-width: $medium_max_content_width;
    // padding-bottom: 31.2%;
  }

  @include desktop {
    width: $large_content_width * 1.25;
    max-width: $large_max_content_width * 1.25;
    // padding-bottom: 31.2%*1.7;
  }

  //   width: 100%;
  @include phone {
    display: none;
  }

  // background: #aaaaaa url('../../img/mindmap.svg');
  // background-position: center;
  // background-size: contain;
  // background-repeat: no-repeat;
  .dekstop {
    margin: 50px 0;
    @include phone {
      display: none;
    }
    .ab339c66-f4d7-4802-89ff-1b5f30cec564,
    .bc2d8202-7a69-4614-b4f2-859f61ae9564,
    .e8181e97-3015-49de-82f8-4bd3baa60643 {
      fill: #fff;
    }

    .a3061281-913c-4864-aec6-ea2ac0e181fc,
    .b2be1c92-1e94-435a-af47-91f31698e216,
    .e3fa05c6-3c9a-48f0-96e5-c1a58cab248b,
    .e8181e97-3015-49de-82f8-4bd3baa60643 {
      stroke: #d5095c;
    }

    .a3061281-913c-4864-aec6-ea2ac0e181fc,
    .b2be1c92-1e94-435a-af47-91f31698e216,
    .bc2d8202-7a69-4614-b4f2-859f61ae9564,
    .e3fa05c6-3c9a-48f0-96e5-c1a58cab248b,
    .e8181e97-3015-49de-82f8-4bd3baa60643 {
      stroke-miterlimit: 10;
    }

    .bc2d8202-7a69-4614-b4f2-859f61ae9564,
    .e8181e97-3015-49de-82f8-4bd3baa60643 {
      stroke-width: 2px;
    }

    .ff2cb404-4e49-40c1-8ee6-96db1f5a6fcb {
      fill: #d5095c;
    }

    .bc2d8202-7a69-4614-b4f2-859f61ae9564 {
      stroke: #00aaa5;
    }

    .a48ef0c9-8d37-44ab-a633-0b3c2900d72e {
      fill: #00aaa5;
    }

    .a3061281-913c-4864-aec6-ea2ac0e181fc,
    .b2be1c92-1e94-435a-af47-91f31698e216,
    .e3fa05c6-3c9a-48f0-96e5-c1a58cab248b {
      fill: none;
      stroke-width: 3px;
    }

    .e3fa05c6-3c9a-48f0-96e5-c1a58cab248b {
      stroke-dasharray: 25.2 25.2;
    }

    .a3061281-913c-4864-aec6-ea2ac0e181fc {
      stroke-dasharray: 25;
    }
  }

  .mobile {
    position: relative;
    padding-bottom: 150%;

    @include no-phone {
      display: none;
    }

    svg {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease-in-out, visibility 0s 0.5s;
      position: absolute;
      top: 0;
      left: 0;

      &.active {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s 0.5s ease-in-out, visibility 0s 0s;
      }
    }

    .main_screen {
      .cls-1,
      .cls-2,
      .cls-7,
      .cls-9 {
        isolation: isolate;
      }

      .cls-2,
      .cls-7,
      .cls-9 {
        font-size: 14px;
        font-family: 'HelveticaNeue-Bold', 'PT Sans';
        font-weight: 700;
      }

      .cls-2,
      .cls-4 {
        fill: #d5095c;
      }

      .cls-10,
      .cls-11,
      .cls-13,
      .cls-3,
      .cls-6,
      .cls-8 {
        fill: none;
      }

      .cls-3,
      .cls-6,
      .cls-8 {
        stroke: #d5095c;
      }

      .cls-10,
      .cls-11,
      .cls-3,
      .cls-8 {
        stroke-miterlimit: 10;
      }

      .cls-10,
      .cls-3 {
        stroke-width: 2px;
      }

      .cls-5,
      .cls-7 {
        fill: #fff;
      }

      .cls-11,
      .cls-13,
      .cls-6,
      .cls-8 {
        stroke-width: 3px;
      }

      .cls-12,
      .cls-9 {
        fill: #777;
      }

      .cls-10,
      .cls-11,
      .cls-13 {
        stroke: #777;
      }
    }

    .rollenarbeit_screen {
      .cls-1,
      .cls-4 {
        fill: #d5095c;
      }

      .cls-2,
      .cls-4,
      .cls-7 {
        isolation: isolate;
      }

      .cls-2,
      .cls-4 {
        font-size: 14px;
        font-family: HelveticaNeue-Bold, Helvetica Neue;
        font-weight: 700;
      }

      .cls-2 {
        fill: #fff;
      }

      .cls-3 {
        letter-spacing: -0.02em;
      }

      .cls-5,
      .cls-6 {
        fill: none;
        stroke: #d5095c;
      }

      .cls-5 {
        stroke-miterlimit: 10;
        stroke-width: 2px;
      }

      .cls-6 {
        stroke-width: 3px;
      }
    }

    .kuenstlerpersoenlichkeit_screen {
      .cls-1,
      .cls-5 {
        fill: #d5095c;
      }

      .cls-2,
      .cls-3,
      .cls-5 {
        isolation: isolate;
      }

      .cls-3,
      .cls-5 {
        font-size: 14px;
        font-family: HelveticaNeue-Bold, Helvetica Neue;
        font-weight: 700;
      }

      .cls-3 {
        fill: #fff;
      }

      .cls-4,
      .cls-7 {
        fill: none;
        stroke: #d5095c;
      }

      .cls-4 {
        stroke-width: 3px;
      }

      .cls-6 {
        letter-spacing: -0.02em;
      }

      .cls-7 {
        stroke-miterlimit: 10;
        stroke-width: 2px;
      }
    }

    .kameraworkshops_screen {
      .cls-1,
      .cls-2,
      .cls-6 {
        isolation: isolate;
      }

      .cls-2,
      .cls-6 {
        font-size: 14px;
        font-family: HelveticaNeue-Bold, Helvetica Neue;
        font-weight: 700;
      }

      .cls-2,
      .cls-5 {
        fill: #d5095c;
      }

      .cls-3,
      .cls-4,
      .cls-8 {
        fill: none;
        stroke: #d5095c;
      }

      .cls-3,
      .cls-4 {
        stroke-miterlimit: 10;
      }

      .cls-3 {
        stroke-width: 1.86px;
      }

      .cls-4 {
        stroke-width: 2px;
      }

      .cls-6 {
        fill: #fff;
      }

      .cls-7 {
        letter-spacing: -0.02em;
      }

      .cls-8 {
        stroke-width: 3px;
      }
    }
  }

  .b87bf366-dc24-45e8-9c05-d8f601d78264 {
    fill: #d5095c;
  }
  .a1aa0b01-03a8-49b0-8ae8-4dafb499fa7b,
  .ba93004f-b080-4240-bcc8-69f1e94fdbe4,
  .bcff5b09-4935-4cd9-9cad-30fd93d65ce7,
  .ea60d3d2-2ab6-4bc0-8f72-b52ed85a5cfa,
  .fdaaf915-9cfd-40a8-afca-f9966278f50e {
    fill: none;
    stroke-miterlimit: 10;
  }
  .a1aa0b01-03a8-49b0-8ae8-4dafb499fa7b,
  .bcff5b09-4935-4cd9-9cad-30fd93d65ce7,
  .ea60d3d2-2ab6-4bc0-8f72-b52ed85a5cfa,
  .fdaaf915-9cfd-40a8-afca-f9966278f50e {
    stroke: #d5095c;
  }
  .ba93004f-b080-4240-bcc8-69f1e94fdbe4,
  .bcff5b09-4935-4cd9-9cad-30fd93d65ce7 {
    stroke-width: 2px;
  }
  .e81ce4f0-e8c9-4ba1-98e8-9dd35f1e5896 {
    fill: #fff;
  }
  .b359b387-5619-48b1-9a70-b0a14cee3377 {
    fill: #00aaa5;
  }
  .ba93004f-b080-4240-bcc8-69f1e94fdbe4 {
    stroke: #00aaa5;
  }
  .a1aa0b01-03a8-49b0-8ae8-4dafb499fa7b,
  .ea60d3d2-2ab6-4bc0-8f72-b52ed85a5cfa,
  .fdaaf915-9cfd-40a8-afca-f9966278f50e {
    stroke-width: 3px;
  }
  .a1aa0b01-03a8-49b0-8ae8-4dafb499fa7b {
    stroke-dasharray: 25.2 25.2;
  }
  .fdaaf915-9cfd-40a8-afca-f9966278f50e {
    stroke-dasharray: 25;
  }
}
