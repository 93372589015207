// Variables
@import './utilities/variables';


.image {
    // padding-bottom: 50%;
    position: relative;
    // background: grey;
    overflow: hidden;

    @include respond-to(handhelds) {
        padding-bottom: $handheld_padding;
    }

    @include respond-to(medium-screens) {
        padding-bottom: $medium_padding;
    }

    @include respond-to(wide-screens) {
        padding-bottom: $large_padding*1.2;
    }

    .parallax-inner {
        // background: red;        
        position: absolute;
        width: 100%;
        height: 100%;

        .image_holder {
            position: absolute;
            width: 100%;
            height: 120%;
            // opacity: .7;
            padding: 0;
            top: -10%;
            // transform: translate(0, -50%);

            background-position: center;
            background-size: cover;

        }
    }

    transition: padding 1s ease;
}