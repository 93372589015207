// Variables
@import './utilities/variables';


.references_block {
    background: $primary;
    // padding: 0 0 31% 0;
    position: relative;
    width: 100%;

    padding: 50px 0;

    @include phone {}


    .button_holder {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translate(-50%, 0);

        button {
            border: 2px solid white;
            background: rgba(255, 255, 255, 0);
            padding: 0;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin: 0 5px;

            &.active,
            &:hover {
                background: rgba(255, 255, 255, 1);
            }
        }
    }

    .height_ghost_wrapper {
        width: 100%;
        overflow-x: hidden;

        .height_ghost {
            width: 2000%;
            position: relative;
            background: green;
            min-height: 50px;
            opacity: 0;

            .single_reference_holder {
                float: left;
                width: 5%;

                .single_reference {
                    position: relative;
                    transform: none;
                    left: 0;
                    top: 0;

                    &.active {
                        transform: none;
                    }

                    @include phone {
                        display: block;

                    }

                }
            }
        }
    }


    .single_reference {
        display: flex;
        width: 100%;
        // height: 100%;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-46%, -50%);
        visibility: hidden;
        opacity: 0;
        transition: opacity .7s ease, visibility 0s 1s, transform 0s 1s;

        @include phone {
            display: block;
            // position: relative;
            // transform: translate(0, 0);
            // left: 0;
            width: 100%;

        }

        &.active {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%);
            transition: opacity .5s .7s ease-in-out, visibility 0s 0s, transform .5s .7s ease;

            @include phone {
                // transform: translate(0, 0);
            }
        }

        width: $phone_content_width;

        @include tablet {
            width: $medium_content_width;
            max-width: $medium_max_content_width;

        }

        @include desktop {
            width: $large_content_width;
            max-width: $large_max_content_width;
        }

        .left {
            width: 50%;
            padding-bottom: 31%;
            // background: yellow;
            position: relative;

            @include phone {
                width: 100%;
                padding: 0;
            }

            .image {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 80%;
                padding-bottom: 50%;
                // background: blue;
                transform: translate(-50%, -50%);

                @include phone {
                    transform: translate(0, 0);
                    left: 0;
                    position: relative;
                    top: 0;
                    margin: 0 auto 50px auto;
                }
            }

        }

        .right {
            width: 50%;
            padding: 5%;
            box-sizing: border-box;

            @include phone {
                transform: translate(0, 0);
                left: 0;
                position: relative;
                top: 0;
                width: $phone_content_width;
                margin: 0 auto;
            }

            p {
                color: white;
                text-align: center;
                font-size: $font_size*1.1;
            }

            p.short {
                font-size: $font_size*1.5;
            }

            .quotee {
                margin-bottom: 0;
            }
        }

    }
}