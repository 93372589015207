// Variables
@import './utilities/variables';

// @include respond-to(medium-screens) {
// @include respond-to(wide-screens) {


.text_with_reference {
    // background: #ff0000aa;
    display: flex;
    flex-direction: row;
    min-height: 50vh;

    @include respond-to(handhelds) {
        display: block;
        min-height: 0;
    }

    &.text_left {
        flex-direction: row-reverse;
    }

    .left {
        position: relative;
        width: 50%;

        // padding-bottom: 50%;
        @include no-phone {
            padding-bottom: 31.5%;

            &.no_parallax {
                padding: 0;
            }
        }

        // float: left;
        background: $bg_grey;


        @include phone {
            width: 100%;
            background: none;

            &.no_parallax {
                background: $bg_grey;
            }
        }

        &.no_parallax {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .no_parallax_image {
            width: 80%;
            margin: 50px 10%;
            text-align: center;

            a {
                margin: 30px 0 0 0;
            }

            img {
                width: 100%;
            }

            @include phone {
                width: 100%;
                margin: 50px 0;
            }

        }


        .slideshow_holder {
            width: 80%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            @include respond-to(handhelds) {
                position: relative;
                width: 100%;
                left: 0;
                top: 0;
                transform: none;

            }

            .manual {
                position: absolute;
                top: 0;
                width: 50%;
                height: 100%;
                background: none;
                opacity: 0.7;
                cursor: pointer;
                transition: opacity .5s ease;
                padding: 0;

                @include phone() {
                    opacity: .7;
                }

                .icon {
                    position: absolute;
                    top: 50%;
                    // left: 50%;
                    transform: translate3d(0, -50%, 0);
                    transform: translate3d(0, -50%, 0);
                    width: 20%;
                    padding-bottom: 20%;
                    // background: #ff000022;
                    transition: transform 1s ease-in-out;
                }

                &:hover {
                    opacity: 1;

                    &.left {
                        .icon {
                            @include no-phone() {
                                transform: translate3d(-10%, -50%, 0);
                            }
                        }
                    }

                    &.right {
                        .icon {
                            @include no-phone() {
                                transform: translate3d(10%, -50%, 0);
                            }
                        }
                    }
                }

                &.left {
                    left: 0;

                    .icon {
                        left: 10%;
                        background-image: url('../../img/icon_back_active.svg');
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;

                    }
                }

                &.right {
                    right: 0;

                    .icon {
                        right: 10%;
                        background-image: url('../../img/icon_forward_active.svg');
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }

            .slides_holder {
                height: 100%;
                // background: rebeccapurple;
                width: 100%;
                position: relative;



                .slide {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    // z-index: 1;
                    transition: opacity 1s ease-in-out;

                    &.active {
                        opacity: 1;
                        // z-index: 2;
                        transition: opacity 1s ease-in-out;
                    }
                }
            }

            a {
                margin: 30px 0 0 0;

                @include phone {

                    margin: 50px 0 0 0;
                }
            }
        }

    }

    .text_block {
        width: 50%;
        // float: left;
        box-sizing: border-box;
        margin: 0;
        padding: 70px 10%;
        align-self: center;

        @include tablet {
            padding: 70px 50px;
        }


        @include respond-to(handhelds) {
            width: 80%;
            padding: 0;
            margin: 50px auto;
        }

        p {
            @include no-phone {
                line-height: 150%;
            }

            // padding-bottom: 50%;
        }
    }


}