// Variables
@import './utilities/variables';

.single_workshop {
  .header_image {
    position: relative;
    background: $bg_grey;
    padding-bottom: $handheld_padding;

    @include tablet {
      padding-bottom: $medium_padding;
    }

    @include desktop {
      padding-bottom: $large_padding;
    }

    .image_holder {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    // .header_image_parallax {
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     background: $bg_grey;
    //     overflow: hidden;

    //     .parallax-inner {
    //         width: 100%;
    //         height: 120%;
    //         position: absolute;
    //         top: -10%;
    //         left: 0;
    //         // background: blue;

    //         // .image_holder {
    //         //     position: absolute;
    //         //     width: 100%;
    //         //     height: 100%;
    //         //     top: 0;
    //         //     left: 0;
    //         // }
    //     }
    // }
  }

  .heading_holder {
    margin: 50px 0;
    font-size: $site_heading_font_size;
  }
}
