// Variables
@import './utilities/variables';

.header_image {
    position: relative;
    text-align: center;

    .image_wrapper {
        // padding-bottom: 50%;
        position: relative;
        // margin-top: 50px;

        @include respond-to(handhelds) {
            padding-bottom: $handheld_padding;
        }

        @include respond-to(medium-screens) {
            padding-bottom: $medium_padding;
        }

        @include respond-to(wide-screens) {
            padding-bottom: $large_padding;
        }

        transition: padding 1s ease;



        .header_image_parallax {
            // padding-bottom: 50%;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            // @include respond-to(handhelds) {
            //     padding-bottom: $handheld_padding;
            // }

            // @include respond-to(medium-screens) {
            //     padding-bottom: $medium_padding;
            // }

            // @include respond-to(wide-screens) {
            //     padding-bottom: $large_padding*1.2;
            // }

            .parallax-inner {
                // background: red;        
                position: absolute;
                width: 100%;
                height: 100%;

                .image_holder {
                    position: absolute;
                    width: 100%;
                    height: 120%;
                    // opacity: .7;
                    padding: 0;
                    top: -10%;
                    // transform: translate(0, -50%);

                    background-position: center;
                    background-size: cover;

                }
            }

            transition: padding 1s ease;
        }
    }

    .heading_holder {
        // background: blue;
        text-align: center;
        margin: 0 auto;

        width: $phone_content_width;


        @include tablet {
            width: $medium_content_width;
            max-width: $medium_max_content_width;
        }

        @include desktop {
            width: $large_content_width;
            max-width: $large_max_content_width;
        }


        h1 {
            position: relative;
            // top: 100%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            margin: 50px 0;
            // font-size: $h1_font_size;
            // font-size: 5vw;
            font-size: $site_heading_font_size;

            @include phone {
                font-size: 10vw;
            }

            font-weight: 900;
            opacity: 1;
            overflow: hidden;
            word-wrap: break-word;
            text-transform: uppercase;

        }
    }
}