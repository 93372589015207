// Variables
@import './utilities/variables';

.newsletter_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $trans_white;
    // background: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 0s 1s;

    @include phone {
        background: $primary;
    }

    &.visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 1s ease, visibility 0s;
    }

    .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $primary;
        padding: 30px;
        min-width: 300px;
        border-radius: 10px;
        text-align: center;
        color: $white;

        @include phone {
            width: 80%;
            padding: 0;
            min-width: unset;
        }

        p,
        h3 {
            color: $white;
        }

        input {
            display: block;
            margin: 20px auto;
            padding: 10px;
            // border-radius: 2px;
            border: none;
            border-bottom: 1px solid $white;
            font-size: $font-size;
            background: none;
            color: $white;
            width: 100%;
            border-radius: 0;
            box-sizing: border-box;

            #first_name {
                display: inline-block;
                width: 47.5%;
                margin: 0;
            }

            #last_name {
                display: inline-block;
                width: 47.5%;
                margin: 0 0 0 5%;
            }

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: $white;
            }
        }

        .loading_overlay {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $primary;
            border-radius: 10px;

            @include phone {
                background: $primary;
            }

            &.visible {
                visibility: visible;
                opacity: 0.7;
            }
        }

        .loading_text {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 900;

            &.visible {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .newsletter_button {
        width: 50px;
        height: 50px;
        // background: $primary;
        position: fixed;
        top: 0;
        right: 0;
        padding: 10px;
        margin: 0;
        border-radius: 0;
        border: none;

        &:hover,
        &:active {
            background: none;
        }

        .bar_holder {
            position: relative;
            width: 100%;
            height: 100%;

            .bar {
                background: $primary;

                @include phone {
                    background: $white;
                }

                position: absolute;
                height: 10%;
                width: 100%;
                transition: opacity 0.5s ease, transform 0.5s ease;

                &:nth-child(1) {
                    top: 0;
                    transform-origin: 0 0;
                    transform: rotate(45deg);
                    width: 130%;
                }

                &:nth-child(2) {
                    top: 45%;
                    opacity: 0;
                }

                &:nth-child(3) {
                    top: 90%;
                    transform-origin: 0% 100%;
                    transform: rotate(-45deg);
                    width: 130%;
                }
            }
        }
    }
}
