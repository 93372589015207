// Variables
@import './utilities/variables';

.mobile_logo {
    top: 10px;
    left: 10px;
    padding-bottom: 12.3%;
    background: url('../img/logo.svg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;

    @include respond-to(handhelds) {
        position: absolute;
        width: 50%;
    }

    @include respond-to(medium-screens) {
        position: fixed;
        width: 200px;
        height: 50px;
        padding-bottom: 0;
    }

    @include respond-to(wide-screens) {
        position: fixed;
        width: 200px;
        height: 50px;
        padding-bottom: 0;
    }
}

.mobile_menu {
    // width: 100%;
    // height: 100%;

    display: none;
    @include menu-small {
        display: block;
    }

    .mobile_menu_overlay {
        width: 100%;
        height: 100%;
        background: $primary;
        position: fixed;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s 0.5s, opacity 0.5s ease;

        .link_holder {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -50%);

            a {
                color: $white;
                opacity: 0;
                font-size: 4vh;
                transform: translate(0, -20px);
                // position: absolute;
                display: block;
                text-align: center;
                padding: 10px 0;
                transition: opacity 0.5s ease, transform 0.5s ease;
                letter-spacing: 0.5px;
                font-family: Helvetica Neue, IBM Plex Sans;
                font-weight: 300;
            }
        }

        .imprint {
            color: white;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 15px;
        }
    }

    .mobile_menu_button {
        width: 50px;
        height: 50px;
        // background: $primary;
        position: fixed;
        top: 0;
        right: 0;
        padding: 10px;
        margin: 0;
        border-radius: 0;
        border: none;

        &:hover,
        &:active {
            background: none;
        }

        .bar_holder {
            position: relative;
            width: 100%;
            height: 100%;

            .bar {
                background: $primary;
                position: absolute;
                height: 10%;
                width: 100%;
                transition: opacity 0.5s ease, transform 0.5s ease;

                &:nth-child(1) {
                    top: 0;
                    transform-origin: 0 0;
                }

                &:nth-child(2) {
                    top: 45%;
                }

                &:nth-child(3) {
                    top: 90%;
                    transform-origin: 0% 100%;
                }
            }
        }
    }

    &.open {
        .mobile_menu_overlay {
            opacity: 1;
            visibility: visible;
            transition: visibility 0s, opacity 0.5s ease;

            a {
                opacity: 1;
                transform: translate(0, 0);

                &:nth-child(1) {
                    transition-delay: 0s;
                }

                &:nth-child(2) {
                    transition-delay: 0.2s;
                }

                &:nth-child(3) {
                    transition-delay: 0.4s;
                }

                &:nth-child(4) {
                    transition-delay: 0.6s;
                }

                &:nth-child(5) {
                    transition-delay: 0.8s;
                }

                &:nth-child(6) {
                    transition-delay: 1s;
                }

                &:nth-child(7) {
                    transition-delay: 1.2s;
                }

                &:nth-child(8) {
                    transition-delay: 1.4s;
                }

                &:nth-child(9) {
                    transition-delay: 1.6s;
                }

                &:nth-child(10) {
                    transition-delay: 1.8s;
                }
            }
        }

        .mobile_menu_button {
            .bar {
                background: $white;

                &:nth-child(1) {
                    top: 0;
                    transform: rotate(45deg);
                    width: 130%;
                }

                &:nth-child(2) {
                    top: 45%;
                    opacity: 0;
                }

                &:nth-child(3) {
                    top: 90%;
                    transform: rotate(-45deg);
                    width: 130%;
                }
            }
        }
    }
}
