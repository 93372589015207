// Variables
@import './utilities/variables';

.text_block {
  text-align: justify;
  width: $phone_content_width;
  margin: 0 auto;
  line-height: 100%;
  // max-height: 2000px;
  // overflow: hidden;

  position: relative;
  margin: 50px auto;
  word-wrap: break-word;
  hyphens: auto;
  transition: max-width 0.5s ease;

  img.person {
    width: 15%;
    margin: 5px 20px 15px 0;
    float: left;
  }

  .text {
    columns: auto 2;
    column-gap: 50px;
    column-fill: balance;

    @include respond-to(handhelds) {
      columns: 1;
      max-width: $medium_max_content_width;
    }

    p {
      @include no-phone {
        line-height: 150%;
      }
    }
  }

  .center {
    text-align: center !important;
  }

  .more_button {
    margin: 50px 0;
  }

  .more_text {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    margin-top: 50px;
    overflow: hidden;

    &.visible {
      opacity: 1;
      visibility: visible;
      max-height: 100000px;
    }
  }

  &.force_no_columns .text {
    columns: 1;
  }

  @include respond-to(medium-screens) {
    width: $medium_content_width;
    max-width: $medium_max_content_width;
  }

  @include respond-to(wide-screens) {
    width: $large_content_width;
    max-width: $large_max_content_width;
  }

  h2 {
    text-align: left;
    line-height: 150%;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &.narrow {
    max-width: 500px;
  }
}
