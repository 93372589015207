// Variables
@import './utilities/variables';

.loading_screen {
    position: fixed;
    background: $primary;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0s;
    z-index: 1005;

    .loading_holder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 100%;
        margin: 0;
        color: white;

        .logo {
            width: 50%;

            // max-width: 100px;
            @include no-phone {
                width: 20%;
                padding-bottom: 6%;
            }

            margin: 0 auto;
            padding-bottom: 18%;
            background: url('../img/logo_white.svg');
            background-repeat: no-repeat;
            background-position: top left;
            background-size: contain;
        }

        span {
            font-size: $h3_font_size;
        }
    }
}



.loading_screen.hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0s 0.5s;
}