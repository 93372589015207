// Variables
@import './utilities/variables';

.workshop_calendar {
  width: $phone_content_width;
  // padding: 0 10%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  overflow: visible;
  margin: 50px auto;

  // transform: translate(0, 0);
  // background: red;
  @include tablet {
    width: $medium_content_width;
    max-width: $medium_max_content_width;
  }

  @include desktop {
    width: $large_content_width;
    max-width: $large_max_content_width;
  }

  .bg {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: $primary;
    visibility: hidden;
    transition: visibility 0s 0.5s ease, opacity 0.5s 0s ease;
  }

  .legende {
    p {
      margin-bottom: 5px;
      text-align: left;
      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        transform: translateY(3px);
        margin-right: 10px;
        background: $primary;
        position: relative;
      }
      @include phone {
        font-size: 14px;
        &::before {
          transform: translateY(5px);
        }
      }
      & {
        color: $primary;
        line-height: 20px;
      }
      &.turquoise {
        color: $turquoise;
        &::before {
          background: $turquoise;
        }
      }
      &.blue {
        color: $blue;
        &::before {
          background: $blue;
        }
      }
    }
  }

  &.single {
    .bg {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s 0s ease, opacity 0.5s 0s ease;
    }
  }

  .month {
    text-transform: uppercase;

    @include no-phone {
      width: 20%;
      text-align: center;
    }
  }

  .single_calender_entry {
    margin: 50px 0;
    display: block;

    @include no-phone {
      margin: 20px 0;

      &:hover {
        // background: grey;
      }
    }

    &.turquoise,
    &.turquoise .right .title,
    &.turquoise .left .day {
      color: $turquoise;
    }

    &.turquoise .right .title.hover:before,
    &.turquoise .right .title.hover:after {
      background-color: $turquoise;
    }

    &.blue,
    &.blue .right .title,
    &.blue .left .day {
      color: $blue;
    }

    &.blue .right .title.hover:before,
    &.blue .right .title.hover:after {
      background-color: $blue;
    }

    .left {
      display: block;
      width: 20%;
      margin-left: -5%;

      @include no-phone {
        margin-left: 0;
      }

      // background: grey;
      float: left;

      .day {
        color: $primary;
        font-size: $h3_font_size;

        @include no-phone {
          font-size: $h3_font_size_large;
        }

        text-align: center;
        display: block;
        font-weight: 900;
      }
    }

    .right {
      display: block;
      width: 85%;

      @include no-phone {
        width: 80%;
      }

      // background: grey;
      float: left;
      margin-left: 5%;
      margin-right: -5%;

      .title {
        text-transform: uppercase;
        font-size: $h3_font_size;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 20px;

        @include no-phone {
          font-size: $h3_font_size_large;
        }

        color: $primary;
      }

      .sub_title {
        display: block;
        margin: 5px 0;
      }
    }
  }

  .single_event {
    .header_image {
      padding-bottom: $handheld_padding;

      @include tablet {
        padding-bottom: $medium_padding;
      }

      @include desktop {
        padding-bottom: $large_padding;
      }
    }
  }
}

.workshop_title {
  text-transform: uppercase;
  font-size: $h3_font_size;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  font-family: IBM Plesk Sans, sans-serif;

  @include no-phone {
    font-size: $h3_font_size_large;
  }

  color: $primary;
}

.workshop_sub_title {
  display: block;
  margin: 5px 0;
  font-family: IBM Plesk Sans, sans-serif;
  color: $primary;
}
